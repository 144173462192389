import React from "react";

function About() {
  return (
    <>
      <h2>WCAG side checker</h2>

      <ul>
        <li>
          Crawler: kan crawle et domæne samt gemme alle sider adresser til at
          evaluere et domæne.
          <br />
          Der skal inputes en gylding domæneadresse.
        </li>
        <br />
        <li>
          Evaluer: domæner der allerede er crawlet er tilføjet listen. Der skal
          vælges et domæne i listen, derefter evalueres domænet.
        </li>
        <br />
        <li>
          Analyser: domæner der allerede er evalueret er tilføjet listen.
          <br />
          Der skal vælges et domæne i listen, derefter analyseres domænet. Der
          kan desuden generes en pdf med beskrivelse af fejl.
        </li>
        <br />
        <li>
          Test af enkelt side: Der skal i input feltet skrives en html adresse
          til det site der skal testes.
        </li>
      </ul>

      <p></p>
    </>
  );
}
export default About;
