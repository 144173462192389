import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

function Domains({domains, startEval, isEvaluating}) {
  const [domain, setDomain] = useState("");

  const mapFunction = (element) => (
    <option key={element.name} value={element.value}>
      {element.name}
    </option>
  );

  let options;
  if (domains) options = domains.map(mapFunction);

  return (
    <>
      <h2>Evaluer</h2>
      <h3>Tilgængelige Domæner</h3>
      <p>Dette er de domæner hvor side adresserne allerede er gemt</p>
      <select
        id="domain-selectbox"
        value={domain}
        onChange={(event) => {
          setDomain(event.target.value);
        }}
        className="domain-selector"
      >
        <option value="">Vælg...</option>
        {options}
      </select>
      <input
        type="button"
        onClick={(event) => startEval(domain)}
        value="Evaluer"
      />
      {isEvaluating && <EvaluatingMessage>Evaluerer domæne</EvaluatingMessage>}
    </>
  );
}

const blinkingAnimation = keyframes`
0% { opacity: 100%; }
50% { opacity: 0%; }
100% { opacity: 100%; }
`;

const EvaluatingMessage = styled.div`
padding: 1em 0;
color: #000000;
font-size: 12px;
font-weight: bold;
animation-name: ${blinkingAnimation};
animation-duration: 2s;
animation-iteration-count: infinite;
`;

export default Domains;
