import React, { useEffect, useState } from "react";
import Result from "./Result";
import StartCrawl from "./StartCrawl";
import Domains from "./Domains";
import "./App.css";
import About from "./About.js";
import SingleSiteTest from "./SingleSiteTest";
import styled from "styled-components";

//const API_URL = "http://localhost:8080/api";
const API_URL = "https://qualweb-site-scanner.dkmnet.dk/api";

function App() {
  const [domains, setDomains] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [visible, setVisible] = useState(); 
  const [isCrawling, setIsCrawling] = useState(false); 
  const [isEvaluating, setIsEvaluating] = useState(false); 
  const [error, setError] = useState(""); 

  useEffect(() => {
    async function fetchData() {
      const url = API_URL + "/domains/";
      const response = await fetch(url);
      const data = await response.json();
      setDomains(data.domains);
      const evalUrl = API_URL + "/evaluated/";
      const evalresponse = await fetch(evalUrl);
      const evaldata = await evalresponse.json();
      setEvaluations(evaldata.evaluated);
    }
    fetchData();
  }, []);

  function startCrawl(crawlDomain) {
    setError("");
    setIsCrawling(true);
    fetch(`${API_URL}/crawl/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: crawlDomain,
      })
    })
    .then(res => {
      setIsCrawling(false);
      if(!res.ok) {
        setError("Forkert URL, skriv hele domænet inkl. protokol (fx. https://www.dkm.dk/)");
      } else {
        setError("");
      }
    })
    .catch(err => console.log(err));
  };

  async function startEval(evalDomain) {
    setIsEvaluating(true);
    const response = await fetch(`${API_URL}/evaluate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        url: evalDomain,
      }),
    });
    if (response) {
      setIsEvaluating(false);
    }
    const data = await response.json();
  }

  async function analysis(Domain) {
    const response = await fetch(`${API_URL}/evaluatedDomain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        domainName: Domain,
      }),
    });
    const data = await response.json();
    return data;
  }

  async function siteTest(url) {
    const response = await fetch(`${API_URL}/evaluateurl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        url: url,
      }),
    });
    const data = await response.json();
    return data;
  }

  return (
    <div className="App">
      <AppTitle>WCAG Side Tester</AppTitle>
      <AppSubtitle>
        Gennemsøg og evaluér et komplet website for optimering af
        webtilgængelighed
      </AppSubtitle>
      <NavBar>
        <NavBtn
          type="button"
          onClick={(event) => {
            setVisible("About");
          }}
          value="Om"
        />
        <NavBtn
          type="button"
          onClick={(event) => {
            setVisible("Crawl");
          }}
          value="Crawl"
        />
        <NavBtn
          type="button"
          onClick={(event) => {
            setVisible("Domains");
          }}
          value="Evaluer"
        />
        <NavBtn
          type="button"
          onClick={(event) => {
            setVisible("Analysis");
          }}
          value="Analyser"
        />
        <NavBtn
          type="button"
          onClick={(event) => {
            setVisible("SingleSiteTest");
          }}
          value="Test af enkelt site"
        />
      </NavBar>
      <div className={`about ${visible === "About" ? "show" : "hide"}`}>
        <About />
      </div>
      <div className={`crawl ${visible === "Crawl" ? "show" : "hide"}`}>
        <StartCrawl startCrawl={startCrawl} error={error} isCrawling={isCrawling} />
      </div>

      <div className={`Domains ${visible === "Domains" ? "show" : "hide"}`}>
        <Domains domains={domains} startEval={startEval} isEvaluating={isEvaluating} />
      </div>
      <div className={`Analysis ${visible === "Analysis" ? "show" : "hide"}`}>
        <Result evaluations={evaluations} analysis={analysis} />
      </div>
      <div
        className={`Analysis ${visible === "SingleSiteTest" ? "show" : "hide"}`}
      >
        <SingleSiteTest siteTest={siteTest} />
      </div>
    </div>
  );
}

export default App;

const NavBar = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 1em 0;
  `;

  const NavBtn = styled.input`
    background: #1a736f;
    color: #fff;
    border: 0;
    padding: 0.65em 1.5em;
    border-radius: 5px;
    margin: 0 0.75em;
    cursor: pointer;
    transition: 0.5s all;
    &:hover {
      background: #249a95;
    }
  `;

  const AppTitle = styled.h1`
    margin: 1em 0 0 0;
    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 50%;
      background: #daa03d;
      margin: 0.25em auto;
    }
  `;

  const AppSubtitle = styled.h2`
    text-align: center;
    font-size: 1.125em;
    font-weight: 300;
    margin: 0.25em 0 1.5em 0;
  `;