import React, { useState } from 'react';
import styled, { keyframes } from "styled-components";


function StartCrawl(props) {
    const [url, setUrl] = useState('');
    
    return (
        <>
        <h2>Crawl et domæne</h2>
        <p>Crawl et nyt eller eksisterende domæne for at gemme/opdatere sideadresserne og tilføje det til listen med tilgængelige domænder</p>
        <input onChange={(event) => setUrl(event.target.value)} type='text' placeholder='Indtast domæne her'/>
        <button onClick={(event) => props.startCrawl(url)} >Crawl domæne</button>
        {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
        {props.isCrawling && <CrawlingMessage>Crawling</CrawlingMessage>}
        </>
    )
}
 export default StartCrawl

 const blinkingAnimation = keyframes`
    0% { opacity: 100%; }
    50% { opacity: 0%; }
    100% { opacity: 100%; }
    `;

 const ErrorMessage = styled.div`
    padding: 1em 0;
    color: red;
    font-size: 12px;
    font-weight: bold;
 `;

const CrawlingMessage = styled.div`
    padding: 1em 0;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    animation-name: ${blinkingAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
`;