import React, { useState, useEffect } from "react";
import styled from "styled-components";

const handleColorType = (color) => {
  switch (color) {
    case "passed":
      return "#296B1E";
    case "failed":
      return "#B70000";
    default:
      return "#1A736F";
  }
};

const passed = styled.div``;

const CardList = styled.div``;

const CardHeader = styled.h3`
  margin: 0;
`;

const CardDescription = styled.p`
  margin: 0.5em 0 2em 0;
`;

const ElementPointer = styled.div`
  background: #1a736f;
  padding: 0.5em 1em;
  font-family: monospace;
  line-height: 1.25em;
`;

const ElementButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ElementWrapper = styled.div`
  display: flex;
  margin: 1em 0 0 0;
`;

const ElementButton = styled.button`
  padding: 8px 15px;
  font-weight: 600;
  border: 0;
`;

const ResultOutcome = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: ${({ color }) => handleColorType(color)};
  font-weight: 300;
  padding: 0.25em 0.75em;
`;

const pdfTitle = styled.h1`
  margin: 1em 0 0 0;
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 50%;
    background: #daa03d;
    margin: 0.25em auto;
  }
`;

const pdfSubtitle = styled.h2`
  text-align: center;
  font-size: 1.125em;
  font-weight: 300;
  margin: 0.25em 0 1.5em 0;
`;

function SingleSiteTest(props) {
  const [siteState, setSiteState] = useState("");
  const [update, setUpdate] = useState(0);
  const [passedVisible, setPassedVisible] = useState(false);
  const [failedVisible, setFailedVisible] = useState(true);
  const [warningVisible, setWarningVisible] = useState(false);
  const [inapplicableVisible, setInapplicableVisible] = useState(false);
  const [totalPassed, setTotalPassed] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [totalWarning, setTotalWarning] = useState(0);
  const [totalInapplicable, setTotalInapplicable] = useState(0);
  const [list, setList] = useState([]);
  let siteTest = props.siteTest;
  let thisData;

  useEffect(() => {
    async function getData() {
      thisData = await siteTest(siteState);
      const jsonData = JSON.parse(thisData);
      setList(jsonData.testreport["@graph"].map(mapFunction));
    }
    getData();
  }, [update]);

  const Card = styled.div`
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 25px 30px;
    background: #249a95;
    color: #fff;
    position: relative;
    a {
      color: #fff;
    }
  `;

  const handleHiddenType = (type) => {
    switch (type) {
      case "passed":
        return passedVisible ? "block" : "none";
      case "failed":
        return failedVisible ? "block" : "none";
      case "cantTell":
        return warningVisible ? "block" : "none";
      case "inapplicable":
        return inapplicableVisible ? "block" : "none";
      default:
        return "block";
    }
  };

  const passedToggle = () => {
    setPassedVisible(!passedVisible);
    setUpdate(update + 1);
  };

  const failedToggle = () => {
    setFailedVisible(!failedVisible);
    setUpdate(update + 1);
  };

  const warningToggle = () => {
    setWarningVisible(!warningVisible);
    setUpdate(update + 1);
  };

  const inapplicableToggle = () => {
    setInapplicableVisible(!inapplicableVisible);
    setUpdate(update + 1);
  };

  function translateOutcome(string) {
    if (string.substring(5) === "passed") {
      return "Bestået";
    }
    if (string.substring(5) === "failed") {
      return "Fejl";
    }
    if (string.substring(5) === "cantTell") {
      return "Advarsel";
    }
    if (string.substring(5) === "inapplicable") {
      return "Kan ikke anvendes";
    } else return;
  }

  const mapFunction = (element, i) => (
    <CardList key={i}>
      <h2>{element.source}</h2>
      <div>
        {element.assertions.map((assertion, j) => (
          <Card
            style={{
              display: handleHiddenType(assertion.result.outcome.substring(5)),
            }}
            className={assertion.result.outcome.substring(5)}
            key={j}
          >
            <CardHeader>{assertion.test.title}</CardHeader>
            <div className="flex">
              <div className="col80">
                <CardDescription>{assertion.test.description}</CardDescription>
                <a href={element.source}>{element.source}</a>
                <br />
                {assertion.result.source.map((source, k) => (
                  <ElementWrapper key={k}>
                    <ElementPointer>{source.result.pointer}</ElementPointer>
                    <ElementButtonWrapper>
                      <ElementButton
                        onClick={() => {
                          navigator.clipboard.writeText(source.result.pointer);
                        }}
                      >
                        Kopier
                      </ElementButton>
                    </ElementButtonWrapper>
                  </ElementWrapper>
                ))}
              </div>
              <div className="col20 right">
                <ResultOutcome color={assertion.result.outcome.substring(5)}>
                  {translateOutcome(assertion.result.outcome)}
                </ResultOutcome>
                <a href={assertion.test["@id"]}>Link to rule</a>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </CardList>
  );

  return (
    <>
      <h2>Test af enkelt site</h2>
      <p>Kør en test på et enkelt side</p>
      <input
        onChange={(event) => setSiteState(event.target.value)}
        type="text"
        placeholder="Indtast side her"
      />
      <button onClick={(event) => setUpdate(update + 1)}>Test side</button>
      <div className="flex summary">
        <div>
          <input
            id="passed-checkbox"
            type="checkbox"
            defaultChecked={passedVisible}
            onChange={passedToggle}
            value="show"
          />
          <label htmlFor="passed-checkbox">
            Godkendt: <strong>{totalPassed}</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input
            id="failed-checkbox"
            type="checkbox"
            defaultChecked={failedVisible}
            onChange={failedToggle}
            value="show"
          />
          <label htmlFor="failed-checkbox">
            Fejl: <strong>{totalFailed}</strong>&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input
            id="passed-checkbox"
            type="checkbox"
            defaultChecked={warningVisible}
            onChange={warningToggle}
            value="show"
          />
          <label htmlFor="warning-checkbox">
            Advarsel: <strong>{totalWarning}</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input
            id="inapplicable-checkbox"
            type="checkbox"
            defaultChecked={inapplicableVisible}
            onChange={inapplicableToggle}
            value="show"
          />
          <label htmlFor="inapplicable-checkbox">
            Uanvendelig: <strong>{totalInapplicable}</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </label>
        </div>
      </div>
      <div>{list}</div>
    </>
  );
}
export default SingleSiteTest;
